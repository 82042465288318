<template>
  <div class="relative">
    <div class="row items-center border-bottom q-px-sm q-py-xs text-grey-7 q-mb-sm">
      <div>
        <h6 class="q-ma-none text-subtitle2 text-weight-bold">
          {{ $t('Picking') }}
        </h6>

        <p class="text-caption text-weight-bold q-ma-none">
          <portal-target name="picking-header"/>
        </p>
      </div>

      <q-space/>

      <q-badge
          v-if="queue"
          :color="color"
          :label="$t(adapter)"
          class="q-py-xs text-capitalize"
      />
    </div>

    <div
        v-if="product"
        :key="`${product.id}:${product.sku}`"
        class="q-mb-sm"
    >
      <product-section :data="product"/>

      <quantity-section
          :data="quantityData"
          :options="quantityOptions"
          @click="handleEvent"
      />

      <counter-section :data="counter" @click="handleEvent"/>
    </div>

    <transition-group name="terminal-item">
      <div
          v-for="(item, i) in data.objects"
          :key="`${item.type}:${i}`"
          class="q-mb-sm"
      >
        <component
            v-if="!item.options || typeof item.options.display !== 'boolean' || item.options.display"
            :is="dynamicComponents[item.type]"
            v-bind="{ data: item.data, rawData: item }"
            :class="item.type === 'assistant' && (data.objects[i + 1] || {}).type === $entities.Orderadmin_Storage_Entity_Place ? 'border-bottom border-bottom--bold' : '' "
            @click="handleClick"
        />
      </div>
    </transition-group>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <counter-modal
        ref="dynamicCounterModal"
        @click="handleEvent"
        @close="handleCounterClose"
    />

    <request-data-modal
        ref="requestDataModal"
        @submit="handleRequestData"
        @close="$emit('unblock')"
    />

    <confirm-modal ref="confirmModal" />

    <error-screen
        :message="error"
        :allowSkip="checkAllowSkip"
        @reset="handleErrorClose"
        @skip="handleErrorSkip"
    />
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'PickingContent',
  emits: ['reset', 'block'],
  props: {
    barcode: {
      type: Object,
      default () {
        return {}
      }
    },
    queues: {
      type: Array,
      default () {
        return []
      }
    },
    place: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      product: null,
      adapterColors: {
        routed_discrete: 'amber',
        routed_batch: 'green',
        totes: 'blue'
      },
      adapterLabels: {
        routed_discrete: 'By ID',
        routed_batch: 'Wave'
      },
      dynamicComponents: {
        'assistant': 'assistant-object',
        'Orderadmin\\Products\\Entity\\AbstractOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\Disposal': 'order-object',
        'Orderadmin\\Storage\\Entity\\Place': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\AbstractPlace': 'place-section-object',
        'message': 'picking-message',
        // 'counter': 'dynamic-counter',
        'Orderadmin\\Storage\\Entity\\Item': 'product-object',
        'Orderadmin\\Storage\\Entity\\Picking\\Queue': 'picking-queue',
        // 'Orderadmin\\Products\\Entity\\Product\\Offer': 'picking-offer',
        // 'Orderadmin\\Storage\\Entity\\Tasks\\Task': 'picking-task',
        collection: 'dynamic-collection',
        'Orderadmin\\Storage\\Entity\\Tasks\\Assistent': 'assistant-object',
        'Orderadmin\\Storage\\Entity\\Movement\\Acceptance': 'acceptance-object',
        batch: 'batch-object',
        'Orderadmin\\Storage\\Entity\\Place\\Row': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Section': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 'place-section-object',
        'Orderadmin\\Storage\\Entity\\Place\\Room': 'place-section-object',
      },
      amountCollapse: false,
      queue: null,
      sequence: null,
      quantity: 1,
      objects: {},
      data: {
        objects: [
          ...this.queues,
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          }
        ]
      },
      error: '',
      errorCode: 0,
      priority: {
        assistant: 100,
        'Orderadmin\\Storage\\Entity\\Place\\Row': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Section': 99,
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 99,
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 99,
        'Orderadmin\\Storage\\Entity\\Place\\Room': 99,
        'Orderadmin\\Storage\\Entity\\AbstractPlace': 99,
        'Orderadmin\\Storage\\Entity\\Place': 99,
        'Orderadmin\\Storage\\Entity\\Tasks\\Task': 98,
        'Orderadmin\\Products\\Entity\\Product\\Offer': 97,
        'collection': 95,
        default: 93
      },
      events: [],
      isLoading: false
    }
  },
  computed: {
    color () {
      if (!this.queue) {
        return ''
      }

      if (this.queue.adapter === 'routed_batch' && this.queue.settings && this.queue.settings['wave-order-limit'] === 1) {
        return 'pink'
      }

      return this.adapterColors[this.queue.adapter]
    },
    adapter () {
      if (!this.queue) {
        return ''
      }

      if (this.queue.adapter === 'routed_batch' && this.queue.settings && this.queue.settings['wave-order-limit'] === 1) {
        return 'Order by order'
      }

      return this.adapterLabels[this.queue.adapter] || this.queue.adapter
    },
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Reset'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        },
        {
          id: 2,
          label: this.$t('Choose Queue'),
          icon: 'grading',
          value: true,
          onChanged: () => {
            this.handleBackToQueues()
          }
        },
        {
          id: 3,
          label: this.$t('Skip Task'),
          icon: 'skip_next',
          value: true,
          onChanged: () => {
            const description = this.$t("You requested to skip task. Are you sure you want to skip current task?")
            this.$refs.confirmModal.show({ description })
              .then(isOk => {
                if (!isOk) {
                  return
                }
                if (this.objects['request-data']) {
                  if (this.objects['request-data'].options && !this.objects['request-data'].options.immediately) {
                    this.$emit('block')
                    this.$refs.requestDataModal.open(this.objects['request-data'])
                  }
                } else {
                  this.handleClick({ event: 'storage.queue.task.reject' })
                }
              })
          }
        }
      ]
    },
    quantityOptions () {
      return {
        max: 'Left to take',
        current: 'You\'ve taken'
      }
    },
    quantityData () {
      return {
        barcode: this.product && this.product.barcodes[0],
        max: ((this.product && this.product.count) || 0) - ((this.product && this.product.scannedCount) || 0),
        current: (this.product && this.product.scannedCount) || 0
      }
    },
    counter () {
      const counterObj = {
        max: ((this.product && this.product.count) || 0) - ((this.product && this.product.scannedCount) || 0),
        current: (this.product && this.product.scannedCount) || 0
      }

      return counterObj
    },
  },
  watch: {
    barcode (newVal) {
      if (this.data.event === 'storage.tasks.task.offer.opened' || this.data.event === 'storage.tasks.scan.item') {
        return this.scanProduct(newVal)
      }

      return this.handleBarcode(newVal)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleEvent (value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          this.quantity = Number(data.value || 0)

          if (this.product) {
            this.lastBarcode = { type: '', value: this.product.sku, raw: this.product.sku }
            return this.handleBarcode(this.lastBarcode)
          }

          return this.handleBarcode(this.barcode)
        },
        'counter.focus': () => {
          this.$emit('block')
        },
        'counter.focusOut': () => {
          this.$emit('unblock')
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    handleRequestData (data) {
      if (data.event === 'storage.tasks.problem.found') {
        this.handleClick(data)
        // if(this.objects['request-data'].options && this.objects['request-data'].options.immediately) {
        //     this.$emit('block')
        //     this.$refs.requestDataModal.open(this.objects['request-data'])
        // }
      } else {
        this.$emit('unblock')
        this.data = { ...this.data, ...data }
        this.handleBarcode({ value: '', raw: '' })
      }
    },
    handleCounterClose () {
      this.quantity = 1

      if (this.product) {
        this.lastBarcode = { type: '', value: this.product.sku, raw: this.product.sku }
        return this.handleBarcode(this.lastBarcode)
      }

      this.handleBarcode(this.barcode)
    },
    getBarcodeFromInstructions (product) {
      return ((product.instructions || []).find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    getBarcodes (product) {
      const barcodesFromInstructions = this.getBarcodeFromInstructions(product)

      if (barcodesFromInstructions.length <= 0) {
        if (product._embedded && product._embedded.productOffer) {
          return product._embedded.productOffer.barcodes || []
        } else {
          let barcodes = (Array.isArray(product.barcodes) && product.barcodes) || []

          if (product.sku) {
            barcodes.push(product.sku)
          }

          return barcodes
        }
      }

      return barcodesFromInstructions
    },
    checkAllowSkip () {
      return this.errorCode === 409
    },
    findTask (barcode) {
      const taskColName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Picking_Task)

      if (this.objects[taskColName]) {
        const product = this.objects[taskColName].data.find(x => {
          const hasBarcode = !!this.getBarcodes(x).find(value => value === barcode.raw)
          return hasBarcode && x.count > (x.scannedCount || 0)
        })

        if (!product) {
          return null
        }

        return { ...product }
      }

      if (
          this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task] &&
          this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer]
      ) {
        const task = this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task].data
        const productOffer = this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer].data
        return { ...task, _embedded: { productOffer } }
      }

      return null
    },
    createProductFromTask (task, barcode) {
      const barcodes = this.getBarcodeFromInstructions(task)
      return {
        ...task,
        name: task._embedded.productOffer.name,
        image: task._embedded.productOffer.image,
        article: task._embedded.productOffer.article,
        barcodes,
        sku: barcode || barcodes[0]
      }
    },
    scanProduct (barcode) {
      this.lastBarcode = { ...barcode }

      if (this.queue.adapter !== 'totes') {
        return this.handleBarcode(barcode)
      }

      const task = this.findTask(barcode)

      if (task) {
        this.product = this.createProductFromTask(task, barcode.raw)
      } else {
        this.product = null
      }

      if (!this.product) {
        return this.handleBarcode(barcode)
      }

      if (this.product.scannedCount >= this.product.count) {
        return this.handleBarcode(barcode)
      }

      return this.$refs.dynamicCounterModal.open({
        ...this.product,
        scannedCount: (this.product.scannedCount || 0) + 1
      })
    },
    handleBack () {
      this.$router.push('/terminal')
    },
    handleReset () {
      this.$emit('reset')
    },
    handleBackToQueues () {
      this.objects = {}
      this.data = {
        objects: [
          ...this.queues,
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          }
        ]
      }
    },
    handleErrorClose () {
      this.error = ''
      this.errorCode = 0
    },
    handleErrorSkip () {
      let previousEvent = this.events[0]

      this.handleErrorClose()

      this.processEvent(previousEvent)
    },
    handleClick (item) {
      if (!item.event) {
        return
      }

      const events = {
        focus: () => {
          this.$emit('block')
        },
        focusOut: () => {
          this.$emit('unblock')
        },
        quantityChange: () => {
          this.quantity = item.data.quantity
        },
        counterSubmit: () => {
          this.quantity = item.data.quantity + 1
          this.handleBarcode({ raw: item.data.barcode, value: item.data.barcode })
        },
        'storage.tasks.queue.opened': () => {
          this.queue = item.data
          this.data.entityClass = item.type
          this.data.event = item.event
          this.sequence = item.sequence

          this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        },
        'storage.tasks.task.offer.opened': () => {
          if (item.data && item.data.componentType === 'collection') {
            this.data.offer = item.data.id
            return this.scanProduct(this.barcode)
          }

          return this.scanProduct({ value: item.barcodes[0], raw: item.barcodes[0], type: '' })
        },
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Picking_Task

          this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.place.opened': () => {
          // this.data.event = 'select.tote.alias'
          // this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Place_Virtual
          this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        },
        'storage.tasks.problem.found': () => {
          this.data.event = item.event
          this.data.entityClass = item.entityClass
          this.handleBarcode({ value: item.event, raw: item.event, type: '' })
        }
      }

      if (events[item.event]) {
        return events[item.event]()
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    handleBarcode (barcode) {
      if (this.data.requiredData && this.data.requiredDataType) {
        const validations = {
          regexp: (value, validation) => {
            const regex = new RegExp(validation)
            return !regex.test(value.raw)
          },
          text: (value, validation) => {
            return value.raw !== validation
          }
        }

        if (!validations[this.data.requiredDataType]) {
          return this.addErrorNotification('Validation is not recognized!')
        }

        if (validations[this.data.requiredDataType](barcode, this.data.requiredData)) {
          return this.addErrorNotification('Barcode does not match scheme!')
        }
      }

      const taskColName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Picking_Task)

      let data = this.reduceData(
          [
            'objects',
            'queue',
            'requiredData',
            'requiredDataType',
            'maxCount',
            'entity',
            'timestamp'
          ],
          {
            timestamp: new Date().toISOString(),
            basket: this.place,
            entity: barcode.raw
          }
      )

      if (data.entityClass === 'Orderadmin\\Storage\\Entity\\Tasks\\Sequence') {
        this.sequence = data.entity
      }

      if (this.sequence && !this.data.sequence) {
        data.sequence = this.sequence
      }

      if (this.data.event === 'storage.tasks.task.offer.opened' || this.data.event === 'storage.tasks.scan.item') {
        data.quantity = this.quantity
      }

      if (this.objects[taskColName]) {
        data.task = this.objects[taskColName].data.reduce((acc, task) => {
          if (task.dublicatedTasks) {
            return [...acc, ...task.dublicatedTasks]
          }

          acc.push(task.id)
          return acc
        }, [])
      }

      if (this.objects['callback-data']) {
        data = { ...this.objects['callback-data'].data, ...data }
      }

      const task = this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task]
          ? { ...this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task] }
          : null

      if (!data.task && task) {
        data.task = task.data.id
      }

      const place = this.getPlaceObject()

      if (place) {
        data.place = place.data.id
      }

      const placeCollection = this.getPlaceCollection()

      if (placeCollection) {
        data.places = placeCollection.data.map(({ id }) => id)
      }

      if (this.objects[this.$entities.Orderadmin_Storage_Entity_Item]) {
        data.item = this.objects[this.$entities.Orderadmin_Storage_Entity_Item].data.id
      }

      const orderObject = this.getOrderObject()

      if (orderObject) {
        data.order = orderObject.data.id
      }

      if (this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer]) {
        data.offer = this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer].data.id
      }

      if (this.objects[this.$entities.Barcode]) {
        data.barcode = this.objects[this.$entities.Barcode].data

        if (this.objects[this.$entities.Barcode].options && this.objects[this.$entities.Barcode].options.quantity) {
          data.quantity = this.objects[this.$entities.Barcode].options.quantity
        }
      }

      if (data.entityClass === this.$entities.Orderadmin_Storage_Entity_Place) {
        this.events = [] // Remove the first element
      }

      this.events.push(data)

      return this.processEvent(data)
    },
    processEvent (data) {
      if (this.isLoading) {
        return Promise.reject('Not too fast')
      }

      const taskColName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Picking_Task)

      this.isLoading = true
      return this.$service.picking.process(this.queue.id, data)
          .then(data => {
            data.objects = [...data.objects].sort((a, b) => {
              let pA = a.options?.priority ?? this.priority[a.type]
              let pB = b.options?.priority ?? this.priority[b.type]

              if (pA > pB) {
                return 1
              }

              if (pA < pB) {
                return -1
              }

              return 0
            })

            console.log(data.objects)

            return data
          })
          .then(data => {
            data.objects = data.objects.reverse()
            return data
          })
          .then(data => {
            this.data = data
            this.quantity = 1

            this.objects = data.objects.reduce((acc, obj) => {
              const key = obj.type === 'collection'
                  ? this.getCollectionName(obj.entityClass)
                  : obj.type

              acc[key] = obj

              return acc
            }, {})

            if (this.objects[taskColName]) {
              this.objects[taskColName] = this.reduceProducts(this.objects[taskColName])

              this.data.objects = this.data.objects.map(x => {
                if (x.type === 'collection' && this.getCollectionName(x.entityClass) === taskColName) {
                  return this.objects[taskColName]
                }

                return x
              })
            } else if (this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task] && this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer]) {
              // Why we merge it instead of the backend return?
              this.objects[taskColName] = {
                type: 'collection',
                entityClass: this.$entities.Orderadmin_Storage_Entity_Picking_Task,
                data: [
                  {
                    ...this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task].data,
                    _embedded: {
                      productOffer: this.objects[this.$entities.Orderadmin_Products_Entity_Product_Offer].data
                    }
                  }
                ],
                options: {
                  clickable: this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task].clickable
                }
              }

              this.data.objects = [...this.data.objects, this.objects[taskColName]]
            }

            if ((this.data.event === 'storage.tasks.task.offer.opened' || this.data.event === 'storage.tasks.scan.item') && !this.barcode.type !== 'S/P') {
              const task = this.findTask(this.lastBarcode || {})

              if (task) {
                this.product = this.createProductFromTask(task, (this.lastBarcode || {}).raw)
              } else {
                this.product = null
              }
            } else {
              this.product = null
            }

            if (this.objects['request-data']) {

              if (this.objects['request-data'].options && this.objects['request-data'].options.immediately) {
                this.$emit('block')
                this.$refs.requestDataModal.open(this.objects['request-data'])
              }

            }
          })
          .catch(err => {
            console.log(err)
            this.error = err.message
            this.errorCode = err.status
          }).finally(() => {
            this.isLoading = false
          })
    },
    reduceProducts (collection) {
      return {
        ...collection,
        data: collection.data.reduce((acc, task) => {
          if (task._embedded && task._embedded.productOffer) {
            const findedTask = acc.find(x => {
              return x._embedded && x._embedded.productOffer && x._embedded.productOffer.id == task._embedded.productOffer.id
            })

            if (findedTask) {
              findedTask.scannedCount = Number(findedTask.scannedCount || 0) + Number(task.scannedCount || 0)
              findedTask.count = Number(findedTask.count || 0) + Number(task.count || 0)
              findedTask.dublicatedTasks.push(task.id)
              return acc
            }
          }

          acc.push({ ...task, dublicatedTasks: [task.id] })
          return acc
        }, [])
      }
    },
    getOrderObject () {
      const entity = this.$entities.orderObjects.find(x => this.objects[x])
      return this.objects[entity]
    },
    getPlaceObject () {
      const entity = this.$entities.placeObjects.find(x => this.objects[x])
      return this.objects[entity]
    },
    getPlaceCollection () {
      const entity = this.$entities.placeObjects.find(x => this.objects[this.getCollectionName(x)])
      return this.objects[this.getCollectionName(entity)]
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
          .filter(key => !ignoredKeys.includes(key))
          .reduce((acc, key) => {
            if (!this.data[key]) {
              return acc
            }

            return {
              ...acc,
              [key]: typeof this.data[key] === 'object' && !Array.isArray(this.data[key])
                  ? this.data[key].id
                  : this.data[key]
            }
          }, { ...defaultValue })
    }
  }
}
</script>
